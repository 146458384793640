import React from 'react';
import * as apis from "../../api";
import { Link } from "react-router-dom";
import { SmileFilled,ExclamationCircleOutlined,AudioOutlined,AudioMutedOutlined } from "@ant-design/icons";
import { clear_points, addPoint, loadObj, apply_scalar} from "./load_obj";
import { fabric } from 'fabric';
import controls from "../index/controls";
import { getQueryStringByName } from '../../common/tool';
import * as THREE from 'three/build/three.module.js';
import get_camera from './camera'
import { Progress, notification, Image, message, Collapse,Modal   } from "antd";
import './index.css'
import {ReactMic} from 'react-mic';
class Work extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: null, // 当前选中的项
            selectedButton: "裁剪",
            orderPrefix:'',
            orderSuffix:'',
            orderInput:'',
            orderDetail:{},
            iscanvas: false,
            apply_scalar_value: 0,
            allDots: [],
            productionMaterialList: [],
            outPanelFabric: "",
            innerPanelFabric: "",
            Sewing_mode: [],
            currentUser: null,
            isNext_step: false, // 下一步按钮是否启用
            isLast_step: false, // 上一步按钮是否启用
            index: 0,
            backgroundColorIndices: [],// 初始化背景颜色索引数组
            progressPercent: 0,// 新增的状态，用于跟踪当前进度百分比
            detailData: {
                fashion: {},
                base_cutpart_list: []
            },
            cts: [],
            canvas2d: undefined,
            window_w: 0,
            window_h: 0,
            isModalVisible: false, // 弹窗是否显示
            isOrderInfoVisible: true, // 订单信息下拉框是否显示
            stepType: 'text',// 新增状态，用于管理当前选择的步骤类型
            isRecording: false , // 新增录音状态
            audioURL: "", // 新增状态，用于存储录音URL
            mediaRecorder: null, // 新增状态，用于存储MediaRecorder实例
            audioChunks: [] // 新增状态，用于存储录音数据
        };
        this.Render_line = this.Render_line.bind(this);


    }

    resizeTimeout = null
    dots = {}
    cut_map = {}
    temp_craft = {}

    async componentDidMount() {
        const setWindowSize = () => {
            const elements = document.getElementsByClassName("content-section-2");
            Array.from(elements).forEach(element => {
                const rect = element.getBoundingClientRect();
                this.setState({ window_w: rect.width, window_h: rect.height });
                this.resizeCanvas();
            });
        };
        this.resizeCanvas();
        window.addEventListener('resize', setWindowSize);
        //启动三维场景
        controls();
        // 页面加载时尝试从 sessionStorage 中获取用户信息
        const currentUserStr = JSON.parse(sessionStorage.getItem('uinfo'));
        const user = currentUserStr.user;
        if (user) {
            try {
                const currentUser = user;
                this.setState({ currentUser });
            } catch (error) {
                console.error('解析用户信息出错:', error);
            }
        }
        const type = getQueryStringByName("type");
        const order_id = getQueryStringByName("order_id");
        let order = type === "B" ? await apis.get_order_detail(order_id) : await apis.get_order_factory_get_order_info({ id: order_id })
        let design_data = await apis.getDesignData(order.need_id)
        const fashion_id = type === "B" ? order.fashion_id : order.clothes_info.fashion_id;
        // 获取款式
        let options = await apis.getOption();
        let detail = await apis.getFashionDetail(fashion_id);
        // 获取款式面板和里板的面料
        if (type === "B") {
            const url = order.base_info.fabric_url.replace("jihe-file.oss-cn-beijing-internal.aliyuncs.com", "files.ifok.cn")
            const fabric = await apis.get_fabric_json(url)
            fabric.forEach((item) => {
                const matchingItem = detail.base_cutpart_list.find((cutpart) => cutpart.code === item.code);
                if (matchingItem) {
                    item.cutpart_type = matchingItem.cutpart_type;
                }
            });
            //对fabric根据cutpart_type进行去重
            const uniqueFabric = Array.from(new Set(fabric.map(item => item.cutpart_type )))
                .map(cutpart_type => fabric.find(item => item.cutpart_type  === cutpart_type ));
            let targetOutPanel, targetInnerPanel;
            // 根据 cutpart_type 分别赋值给 targetOutPanel 和 targetInnerPanel
            uniqueFabric.forEach(item => {
                if (item.cutpart_type === '面板') { // 假设 'out' 是外板的类型
                    targetOutPanel = item.name;
                } else if (item.cutpart_type === '里板') { // 假设 'inner' 是内板的类型
                    targetInnerPanel = item.name;
                }
            });           
            await this.fetchSewingMode(fashion_id,type, targetOutPanel, targetInnerPanel);
        } else if (type === "C") {
            await this.fetchSewingMode(fashion_id, type,order.clothes_info.fabric.name);
        }

        if (!detail.fashion.algorithm_param.wedo) {
            detail.fashion.algorithm_param.wedo = [{ "tw": 0 }]
        }
        this.setState({ loading: false, detailData: detail, craft_methods: options.craft_methods,orderDetail:order })
        // 加载3d模型
        if (detail.fashion.obj_url) {
            const orderurl = order.base_info.texture_url.replace("jihe-file.oss-cn-beijing-internal.aliyuncs.com", "files.ifok.cn")
            const res = await this.fetchAndGroupData(orderurl);
            loadObj(res, design_data.obj_url.replace("jihe-file.oss-cn-beijing-internal.aliyuncs.com", "files.ifok.cn"))
            // loadObj(res,'https://files.ifok.cn/2025-01-08/7282650815393849344.obj')
        }

        // 加载2d点模型
        if (detail.fashion.cutpart_border_point) {
            const production_material = await apis.get_production_material(order.id);
            let response = await fetch( design_data.cut_info_jsons);
            // let response = await fetch('https://files.ifok.cn/2025-01-09/7282928303693000704.json');
            let ret = await response.json();
            let canvas2d = new fabric.Canvas('con', { 
                stopContextMenu : true,
                fireRightClick : true,
                centered: true 
            }); // 设置画布居中显示
            // 设置画布的一些属性
            canvas2d.hoverCursor = 'pointer';
            canvas2d.moveCursor = 'pointer';
            canvas2d.selection = false;
            // 给画布添加鼠标滚轮事件监听器，用于缩放画布
            canvas2d.on("mouse:wheel", (e) => {
                let zoom = (e.e.deltaY > 0 ? -0.3 : 0.3) + canvas2d.getZoom();
                zoom = Math.max(0.1, zoom); // 最小缩放比例为原始的 1/10
                zoom = Math.min(5, zoom); // 最大缩放比例为原始的 5 倍
                let zoomPoint = new fabric.Point(e.pointer.x, e.pointer.y);

                // 取消之前的缩放动画请求
                if (canvas2d.zoomRequest) {
                    cancelAnimationFrame(canvas2d.zoomRequest);
                }

                // 使用 requestAnimationFrame 实现平滑缩放
                canvas2d.zoomRequest = requestAnimationFrame(() => {
                    canvas2d.zoomToPoint(zoomPoint, zoom);
                    canvas2d.zoomRequest = null;

                    // 使用 CSS 进行缩放
                    const canvasElement = canvas2d.lowerCanvasEl;
                    const currentTransform = canvasElement.style.transform || 'scale(1)';
                    const currentScale = parseFloat(currentTransform.match(/scale\(([^)]+)\)/)[1]);
                    const newScale = currentScale * (zoom / canvas2d.getZoom());
                    canvasElement.style.transform = `scale(${newScale})`;
                });

                // 当缩放时隐藏页面滚动条
                document.body.style.overflow = "hidden";
            });
            // 监听鼠标按下事件
            let isPanning = false;
            let lastPointer = null;
            let panRequest = null;
            
            canvas2d.on("mouse:down", (e) => {
                isPanning = true;
                lastPointer = new fabric.Point(e.pointer.x, e.pointer.y);
            });
            // 监听鼠标移动事件
            canvas2d.on("mouse:move", (e) => {
                if (isPanning && lastPointer) {
                    if (!panRequest) {
                        panRequest = requestAnimationFrame(() => {
                            let delta = new fabric.Point(e.pointer.x - lastPointer.x, e.pointer.y - lastPointer.y);
                            canvas2d.relativePan(delta);
                            lastPointer = new fabric.Point(e.pointer.x, e.pointer.y);
                            panRequest = null;
                        });
                    }
                }
            });
            // 监听鼠标释放事件
            canvas2d.on("mouse:up", () => {
                isPanning = false;
                lastPointer = null;
                if (panRequest) {
                    cancelAnimationFrame(panRequest);
                    panRequest = null;
                }
            });    
            // 遍历获取的 JSON 数据
            for (let i = 0; i < ret.length; i++) {
                try {
                    // 存储一些数据到 this.cut_map
                    this.cut_map[ret[i].code] = ret[i];
                    // // 处理每个对象的 interior 数据
                    // for (let j = 0; j < ret[i].dxf_info.before.interior.length; j++) {
                    //     // 将 interior 中的 x 和 y 坐标除以算法参数中的 uv_width
                    //     ret[i].dxf_info.before.interior[j][0] = parseFloat(ret[i].dxf_info.before.interior[j][0]) / parseFloat(ret[i].dxf_info.uvcloLength);
                    //     ret[i].dxf_info.before.interior[j][1] = parseFloat(ret[i].dxf_info.before.interior[j][1]) / parseFloat(ret[i].dxf_info.uvcloLength);
                    // }
                    // // 处理每个对象的 roughPeak 数据
                    // for (let j = 0; j < ret[i].dxf_info.before.roughPeak.length; j++) {
                    //     // 同样将 roughPeak 中的 x 和 y 坐标除以算法参数中的 uv_width
                    //     ret[i].dxf_info.before.roughPeak[j][0] = ret[i].dxf_info.before.roughPeak[j][0] / parseFloat(ret[i].dxf_info.uvcloLength)
                    //     ret[i].dxf_info.before.roughPeak[j][1] = ret[i].dxf_info.before.roughPeak[j][1] / parseFloat(ret[i].dxf_info.uvcloLength)
                    // }
                } catch (error) {
                    // 如果处理过程中出现错误，则忽略该错误
                }
            }
            this.setState({productionMaterialList: production_material.list,cts: ret, canvas2d }, async () => {
                this.addPointToCanvas();
            })
        }
        const {allDots, productionMaterialList,canvas2d}= this.state
        const groupedDots = allDots.reduce((acc, item) => {
            if (item.name.startsWith('dot_')) {
                if (!acc[item.name]) {
                    acc[item.name] = [];
                }
                acc[item.name].push(item);
            }
            return acc;
        }, {});
        const calculateGroupProperties = (group) => {
            let minTop = Infinity, maxTop = -Infinity;
            let minLeft = Infinity, maxLeft = -Infinity;
    
            group.forEach(point => {
                if (point.top < minTop) minTop = point.top;
                if (point.top > maxTop) maxTop = point.top;
                if (point.left < minLeft) minLeft = point.left;
                if (point.left > maxLeft) maxLeft = point.left;
            });
            const width = maxLeft - minLeft;
            const height = maxTop - minTop;
    
            return {width, height,maxLeft, minLeft, maxTop, minTop };
        };
    
        const groupProperties = {};
        for (const key in groupedDots) {
            const group = groupedDots[key];
            groupProperties[key] = calculateGroupProperties(group);
        }

        // productionMaterialList.forEach(item => {
        //     const imgsrc = item.cutpart_url_lower;
        //     const groupKey = `dot_${item.code}`; // 假设 item.code 是对应的组名
        //     const group = groupProperties[groupKey];
        //     const groupWidth = group.width /0.9
        //     const groupHeight = group.height / 0.9
        //     const sc =Math.max(groupWidth,groupHeight)
        //     fabric.Image.fromURL(imgsrc, (img) => {
        //         img.scaleX = sc/img.width
        //         img.scaleY = sc/img.height
        //         img.set({
        //             left: (group.maxLeft + group.minLeft - img.getScaledWidth()) / 2,
        //             top: (group.maxTop + group.minTop - img.getScaledHeight()) / 2,
        //             flipX: true, 
        //             selectable: false,
        //             hasBorders: false,

        //         });
        //         canvas2d.add(img);
        //         img.sendToBack();
        //         canvas2d.renderAll();
        //     })
        // });
        let imglist = []
        // 批量加载和渲染图片
        const imagesToLoad = productionMaterialList.map(item => {
            const imgsrc = item.cutpart_url_lower;
            const groupKey = `dot_${item.code}`; // 假设 item.code 是对应的组名
            const group = groupProperties[groupKey];
            const groupWidth = group.width / 0.9;
            const groupHeight = group.height / 0.9;
            const sc = Math.max(groupWidth, groupHeight);
            return new Promise((resolve) => {
                fabric.Image.fromURL(imgsrc, (img) => {
                    img.scaleX = sc / img.width;
                    img.scaleY = sc / img.height;
                    img.set({
                        left: (group.maxLeft + group.minLeft - img.getScaledWidth()) / 2,
                        top: (group.maxTop + group.minTop - img.getScaledHeight()) / 2,
                        flipX: true,
                        selectable: false,
                        hasBorders: false,
                    });
                    imglist.push(img)
                    resolve();
                });
            });
        });
        // 等待所有图片加载完成后再渲染
        Promise.all(imagesToLoad).then(() => {
            canvas2d.add(...imglist);
            imglist.forEach(img => {
                img.sendToBack();
            });
            canvas2d.renderAll();
        });
    }
    toggleRecording = async () => {
        const { isRecording } = this.state;
        if (isRecording) {
            console.log('停止录音');
            // this.state.mediaRecorder?.stop();
            // this.setState({ isRecording: false });
        }else{
            console.log('开始录音');
            // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            // const mediaRecorder = new MediaRecorder(stream);
            // const audioChunks = [];
      
            // mediaRecorder.ondataavailable = (event) => {
            //   audioChunks.push(event.data);
            // };
      
            // mediaRecorder.onstop = () => {
            //   const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
            //   const url = URL.createObjectURL(audioBlob);
            //   this.setState({ audioURL: url });
            // };
      
            // mediaRecorder.start();
            // this.setState({ isRecording: true, mediaRecorder, audioChunks });
        }
    }
    startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        this.setState({ mediaRecorder, audioChunks: [] });
    
        mediaRecorder.ondataavailable = (event) => {
          this.setState((prevState) => ({
            audioChunks: [...prevState.audioChunks, event.data]
          }));
        };
    
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.state.audioChunks, { type: "audio/wav" });
          this.setState({ audioURL: URL.createObjectURL(audioBlob) });
        };
    
        mediaRecorder.start();
        this.setState({ isRecording: true });
      };
    
      stopRecording = () => {
        this.state.mediaRecorder?.stop();
        this.setState({ isRecording: false });
      };
    resizeCanvas() {
        const elements = document.getElementsByClassName("content-section-2");
        Array.from(elements).forEach(element => {
            const rect = element.getBoundingClientRect();
            this.setState({ window_w: rect.width, window_h: rect.height });
        });
    }

    async fetchAndGroupData(url) {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          const result = [];
          const resultMap = new Map(Object.entries(data));
          resultMap.forEach((value, key) => {
            result.push({
              code: key,
              value: value,
            });
          });
          return result;
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    }
    //2D画布绘点
    addPointToCanvas() {
        let { cts, canvas2d, detailData } = this.state;
        if (!detailData.base_cutpart_list) {
            detailData.base_cutpart_list = [];
        }
        //创建点信息
        const createDot = (x, y, name, code, index,offsetX,offsetY) => {
            return new fabric.Circle({
                left: x+offsetX ,
                top: y+offsetY ,
                radius: 1,
                fill: 'green',
                objectCaching: false,
                name: `${name}_${code}`,
                selectable: false,
                visible: true,
                movable: false,
                index: index
            });
        };
        //获取点xy坐标的最大值最小值
        const getMinMax = (points) => {
            let xValues = points.map(point => point[0]);
            let yValues = points.map(point => point[1]);
        
            const minX = Math.min(...xValues);
            const maxX = Math.max(...xValues);
            const minY = Math.min(...yValues);
            const maxY = Math.max(...yValues);
        
            return { minX, maxX, minY, maxY };
        };
        //添加点
        const addDots = (points, name, code,offsetX,offsetY) => {
            const dots = [];
            for (let index = 0; index < points.length; index++) {
                const point = points[index];
                // 检查 point[0] 和 point[1] 是否都为 0
                if (point[0] !== 0 || point[1] !== 0) {
                    const dot = createDot(point[0], point[1], name, code, index, offsetX, offsetY);
                    dots.push(dot);
                }
            }
            return dots;
        };

        let allDots = [];
        let currentCode = null;
        let offsetX = 0;
        let offsetY = 0;
        let width = 0;
        let offsetXCount = 0;
        let maxMaxY = 0;
        let height = 0;
        for (let i = 0; i < cts.length; i++) {
            let item = cts[i];
            //将点0-1坐标转化为实际大小
            item.cleanPlate.forEach(element => {
                element[0] = element[0] * 500/item.ratio ;
                element[1] = element[1] * 500/item.ratio ;
            });
            item.interior.forEach(e => {
                e[0] = e[0] * 500/item.ratio ;
                e[1] = e[1] * 500/item.ratio ;
            });
            let dots = []
            let interior_dots = []
            const { minX, maxX, minY, maxY } = getMinMax(item.cleanPlate);
            //统一裁片间距
            offsetX =-minX +50
            offsetY = -minY+height
            if (maxY >maxMaxY) {
                maxMaxY = maxY
            }
            if (currentCode === null) {
                currentCode = item.code;
                dots = addDots(item.cleanPlate, "dot", item.code,offsetX+width,offsetY);
                interior_dots = addDots(item.interior, "interior_dots", item.code,offsetX+width,offsetY);
                
            }else{
                if (currentCode !== item.code) {
                    dots = addDots(item.cleanPlate, "dot", item.code,offsetX+width,offsetY);
                    interior_dots = addDots(item.interior, "interior_dots", item.code,offsetX+width,offsetY);
                }
            }
            width += maxX +offsetX
            offsetXCount+= width;
            if (offsetXCount > 2000) {
                offsetXCount = 0;
                offsetX = 0;
                offsetY =0
                height += maxMaxY +50
                maxMaxY = 0;
                width = 0;
            }
            

            // let roughPeak_dots = addDots(item.dxf_info.before.roughPeak, "roughPeak_dots", item.code);

            const itemDots = [...dots,...interior_dots];
            allDots = [...allDots, ...itemDots];
            this.dots[item.code] = dots;
            // this.dots[`roughPeak_dots_${item.code}`] = roughPeak_dots;
            this.dots[`interior_dots_${item.code}`] = interior_dots;
        }
        //获取画布的大小
        const canvas = document.getElementById("con");
        const canvasWidth = canvas.width / 2;
        const canvasHeight = canvas.height / 2;
        const centerPoint = [canvasWidth, canvasHeight];
        // 1. 计算旧的中心点
        const oldCenterX = centerPoint[0];
        const oldCenterY = centerPoint[1];

        // 2. 将所有点绕旧的中心点旋转180度
        allDots.forEach(dot => {
            const newX = oldCenterX - (dot.left - oldCenterX);
            const newY = oldCenterY - (dot.top - oldCenterY);
            dot.set({ left: newX, top: newY });
        });

        // 3. 计算新的中心点
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
        allDots.forEach(dot => {
            minX = Math.min(minX, dot.left);
            minY = Math.min(minY, dot.top);
            maxX = Math.max(maxX, dot.left);
            maxY = Math.max(maxY, dot.top);
        });

        const newCenterX = (minX + maxX) / 2;
        const newCenterY = (minY + maxY) / 2;

        // 4. 计算差值
        const deltaX = newCenterX - oldCenterX;
        const deltaY = newCenterY - oldCenterY;

        // 5. 将所有点平移到新的中心点位置
        allDots.forEach(dot => {
            dot.set({ left: dot.left - deltaX, top: dot.top - deltaY });
        });
            // 创建一个位于 (0, 0) 且颜色为红色的点
    // const redDot = new fabric.Circle({
    //     left: 0,
    //     top: 33,
    //     radius: 10,
    //     fill: 'red',
    //     objectCaching: false,
    //     name: 'dot_',
    //     selectable: false,
    //     visible: true,
    //     movable: false
    // });
    // allDots.push(redDot);
        this.setState({allDots})
        canvas2d.add(...allDots);
        canvas2d.renderAll();


    }

    //取出需要高亮显示的数据
    fetchSewingMode =  async (fashion_id,type, targetOutPanel, targetInnerPanel) => {
        const Sewing_mode = await apis.getProcess(fashion_id, targetInnerPanel, targetOutPanel,type);
        if (Sewing_mode.length <= 0) {
            return
        }
        const sewing_modeValue = Sewing_mode.craftProcess
        this.setState({
            outPanelFabric: Sewing_mode.craft.out_fabric,
            innerPanelFabric: Sewing_mode.craft.in_fabric,
            Sewing_mode: sewing_modeValue,
        });

    };

    //更改背景颜色
    calculateBackgroundColor(lineIndex) {
        const { backgroundColorIndices } = this.state;
        return backgroundColorIndices.includes(lineIndex) ? '#fadada' : '#ffffff'
    }

    //用户登出
    handleLogout = () => {
        sessionStorage.removeItem('uinfo');
        window.location.href = '/login'; // 重定向到登录页面
    };

    //渲染缝纫线
    Render_line(ayy) {
        // 入参: 缝纫线的数据
        if (!ayy) {
            // 清空上一次的缝纫线
            this.restorePreviousCutPartColors();//清理2d画布
            this.temp_craft = []
            this.redraw_points();// 清空3d模型的红线
            notification['warning']({
                description:
                    '该步骤未设置缝纫线',
                duration: 1.5,
            });

            console.error("缝纫线为空");
            return;
        }
        this.restorePreviousCutPartColors();
        // 遍历每个裁片
        this.temp_craft = {}
        Object.keys(ayy).forEach(key => {
            // 需要在哪个裁片上显示高亮 key是裁片名
            const indices = ayy[key];
            // 取lines中的数组,需要高亮的点的位置
            indices.forEach(indic => {
                // 拿到对应裁片的全部点位dots
                const cutPart = this.dots[key];
                // 如果cutPart不为空
                if (!cutPart) {
                    console.error("裁片数据不存在:err003");
                    return;
                }
                this.changePointColors(cutPart, indic);
                // 更新 temp_craft
                if (!this.temp_craft[key]) {
                    this.temp_craft[key] = [];
                }
                this.temp_craft[key].push(indic);
            });
        });
        // 重绘
        this.redraw_points();
        // const filterarr = window.objects_3d.children.filter(item => item.children.length > 0);
        // window.objects_3d.updateMatrixWorld();
    
        // // **复位 rotation.y**
        // window.objects_3d.rotation.y = 0; 
    
        // // **初始化合并包围盒**
        // const mergedBox = new THREE.Box3();
    
        // // **计算合并包围盒**
        // filterarr.forEach(item => {
        //     const box = new THREE.Box3().setFromObject(item);
        //     mergedBox.union(box);
        // });
    
        // // **获取子对象的中心点（世界坐标）**
        // const center = mergedBox.getCenter(new THREE.Vector3());
        // let deltaX = center.x
        // let deltaZ = center.z
        // console.log("deltaX,deltaZ",deltaX,deltaZ);
        
        // // **计算角度 (绕 Y 轴旋转)**
        // // let angleRad = Math.atan2(deltaX, deltaZ); // 计算弧度
        // let angleRad = Math.atan2(deltaZ, deltaX); // 计算弧度
        // console.log("旋转角度 (弧度):", angleRad);
        // console.log("旋转角度 (度):", THREE.MathUtils.radToDeg(angleRad));
        // if (deltaZ > 0 ) {
        //     if (deltaX < 0) {
        //         window.objects_3d.rotation.y = -angleRad/2 + Math.PI;
        //         console.log(Math.PI);
                
        //         console.log("当前",window.objects_3d.rotation.y);
                
        //     }else{ 
        //         window.objects_3d.rotation.y = -angleRad/2
        //         console.log("当前",window.objects_3d.rotation.y);
        //     }
        // }else{
        //     if (deltaX < 0 ) {
        //         window.objects_3d.rotation.y = -angleRad
        //         console.log("当前",window.objects_3d.rotation.y);
        //     }else{
        //         window.objects_3d.rotation.y = -angleRad + Math.PI;
        //         console.log("当前",window.objects_3d.rotation.y);
        //     }
        // }
        // console.log("center",center.x,center.y,center.z);


        // console.log("objects_3d.rotation.y (最终):", window.objects_3d.rotation.y);
    }

    restorePreviousCutPartColors = () => {
        // 恢复点的颜色
        const cutPot = this.dots;
        Object.keys(cutPot).forEach(key => {
            cutPot[key].forEach(item => {
                item.fill = 'green'; // 假设原始颜色存储在一个变量中
                item.radius = 1;
            });
        });
        // 重新绘制画布
        const { canvas2d } = this.state;
        canvas2d.renderAll();
    };

    changePointColors = (cutPart, indic) => {
        const { canvas2d } = this.state;
        // 获取对应的点数组
        const dots = cutPart; // 假设 cutPart 已经是点数组
        // 改变点的颜色
        indic.forEach(index => {
            if (index >= 0 && index < dots.length) {
                const dot = dots[index];
                dot.fill = 'red';
                dot.radius = 1;
            }
        });
        // 重新绘制画布
        canvas2d.renderAll();
    };
    handleButtonClick = (buttonText) =>{
        this.setState({
            selectedButton: buttonText,
        });
        const { Sewing_mode } = this.state;
        const index = Sewing_mode.findIndex(craftProcess => craftProcess.classify === buttonText);
        if (index !== -1) {
            this.setState({ selectedIndex: index }, () => {
                if (this.scrollToElement) {
                    this.scrollToElement.scrollIntoView({ behavior: 'smooth' });
                }
            });
        }
    }
    redraw_points() {
        clear_points()
        for (let code in this.temp_craft) {
            if (!code.startsWith("interior_dots_") && !code.startsWith("roughPeak_dots_")) {
                for (let n = 0; n < this.temp_craft[code].length; n++) {
                    let vs = []
                    for (let m = 0; m < this.temp_craft[code][n].length; m++) {
                        vs.push(this.cut_map[code].v[this.temp_craft[code][n][m]])
                    }
                    addPoint(vs, window.code_maps[code])
                }
            }

        }
    }
    handleCnvasSize=()=>{
        this.setState(prevState => ({
            iscanvas: !prevState.iscanvas
        }), () => {
            // 确保在状态更新后执行
            const { iscanvas } = this.state;
            if (typeof iscanvas === 'boolean') {
                window.renderer.setSize(iscanvas ? this.state.window_w : 225, iscanvas ? this.state.window_h : 225);
                window.camera.aspect = this.state.window_w / this.state.window_h
                window.camera.updateProjectionMatrix();
                
            } else {
                console.error('Invalid state value for iscanvas');
            }
        });
    }
    progressPercent() {
        const { Sewing_mode, backgroundColorIndices } = this.state;
        if (Sewing_mode.length <= 0) {
            return
        }
        const newProgressPercent = Math.min(Math.round((backgroundColorIndices.length / Sewing_mode.length) * 100), 100);
        this.setState({ progressPercent: newProgressPercent });
    }

    render() {
        let {
            selectedIndex,
            selectedButton,
            apply_scalar_value,
            outPanelFabric,
            innerPanelFabric,
            progressPercent,
            currentUser,
            Sewing_mode,
            isNext_step,
            isLast_step,
            isModalVisible, // 弹窗是否显示
            isOrderInfoVisible, // 订单信息下拉框是否显示
            orderInput,
            stepType, // 获取当前选择的步骤类型
            isRecording, // 新增录音状态
            audioURL
        } = this.state;
        const userName = currentUser ? currentUser.name : '';
        const NewMap = new Map();
        const { Panel } = Collapse;
        // 进度列表
        const renderEdgeLineBlocks = (Sewing_mode) => {
            // 遍历craftLines中的每一个对象
            return Sewing_mode.map((craftProcess, lineIndex) => {
                NewMap.set(`${lineIndex}`, craftProcess);
                // 解析数据
                const selectables = craftProcess.selected_crafts.params || [];
                const equipment = craftProcess.equipments.params || [];
                const hasRiskWarning = craftProcess.selected_crafts && craftProcess.selected_crafts.risk_warning;
                return (
                    <div 
                    key={lineIndex} 
                    className="edge-line-block" 
                    style={{ marginBottom: '20px' }}
                    ref={lineIndex === selectedIndex ? (el) => this.scrollToElement = el : null} // 添加 ref
                    >
                        <div style={{
                            backgroundColor: this.calculateBackgroundColor(lineIndex),
                            boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)'
                        }}
                            className='progress_list'>
                            <div className='progress_data_container'>
                                <div className='progress_data'>{`${lineIndex + 1}/${Sewing_mode.length}`}</div>
                                
                                <div className='progress_data_title'>
                                    {craftProcess.craft_name}
                                    {hasRiskWarning && (
                                        <ExclamationCircleOutlined style={{ color: 'red', marginLeft: 8, marginRight: 8 }} />
                                    )}
                                </div>
                            </div>
                            <div>
                                {craftProcess.equipments.name && (<div className='progress_title'>
                                    设备：<span>{craftProcess.equipments.name}</span>
                                </div>)}
                                {equipment && (<div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {equipment.map((item, idx) => (
                                        <div key={idx} className='data_parameters'>
                                            {item.key}{item.value}
                                            {this.state.stepType === 'image' && item.img_url && (
                                                <Image className='parameters_image' src={item.img_url} alt={`${item.key} 图片`} style={{ width: '50px', height: '50px', marginLeft: '8px' }} />
                                            )}
                                        </div>
                                        
                                    ))}
                                </div>)}
                            </div>
                            {craftProcess.selected_crafts.name && (<div>
                                <div className='progress_title'>
                                    工艺：<span>{craftProcess.selected_crafts.name}</span>
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {selectables.map((item, idx) => (
                                        <div key={idx} className='data_parameters'>
                                            {item.key}{item.value}
                                            {this.state.stepType === 'image' && item.img_url && (
                                                <Image className='parameters_image' src={item.img_url} alt={`${item.key} 图片`} />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>)}
                            <div>
                                {craftProcess.selected_crafts.description}
                            </div>
                            {/* 根据 stepType 的值决定是否展示图片 */}
                            {this.state.stepType === 'image' && craftProcess.selected_crafts.image_url && (
                                <Image className='parameters_image' src={craftProcess.selected_crafts.image_url} alt='示意图' />
                            )}                    
                            {this.state.stepType === 'image' && hasRiskWarning && (
                                <div className='risk_warning'>
                                    <div className='risk_warning_title'><ExclamationCircleOutlined style={{ color: 'red', marginLeft: 8, marginRight: 8 }} />风险警示</div>
                                    <div className='risk_warning_content'>{craftProcess.selected_crafts.risk_warning}</div>
                                </div>
                            )}
                            {/* {this.state.stepType === 'image' && (
                                <div className='audio'>
                                    <span className='audio_title'>建议反馈:</span>
                                    <div className='audio_icon_container'>
                                        <div className='audio_icon' onClick={this.toggleRecording}>
                                            {isRecording ? <AudioMutedOutlined style={{ color: '#fffcfd', fontSize: '50px' }} /> : <AudioOutlined style={{ color: '#fffcfd', fontSize: '50px' }} />}
                                            {audioURL && <audio controls src={audioURL} />}
                                        </div>
                                    </div>
                                </div>
                            )} */}

                        </div>
                    </div>
                );
            });
        };
        // 弹窗内容
        const handleOk = () => {
            const orderNumber = this.state.orderDetail.id;
            const lastFourDigits = orderNumber.slice(-4);
            const inputDigits = this.state.orderInput;

            if (inputDigits === lastFourDigits) {
                this.setState({
                    isModalVisible: false,
                    isOrderInfoVisible: true, // 收起订单信息下拉框
                });
                let { index, backgroundColorIndices } = this.state;
                if (NewMap.size === 0) {
                    message.error('未设置工艺流程');
                    return;
                }
                const newVar = NewMap.get(`${index}`);
                const ayy = JSON.parse(`${newVar.lines}`);

                this.Render_line(ayy);
                backgroundColorIndices.push(index);
                this.setState({
                    index: index,
                    isNext_step: true,
                    isLast_step: true,
                    backgroundColorIndices: backgroundColorIndices,
                });
                const hasRiskWarning = newVar.selected_crafts && newVar.selected_crafts.risk_warning;
                if (hasRiskWarning) {
                    notification.warning({
                        message: '风险警告',
                        description: newVar.selected_crafts.risk_warning,
                        duration: 4.5, // 提示窗口显示时间，单位秒
                        style: {
                            top: '28%',
                            left: '55%',
                            transform: 'translate(-50%, -50%)',
                            position: 'fixed',
                            color: 'red',
                        },
                    })
                } else{
                    notification.destroy()
                }   
                this.progressPercent();
            } else {
                message.error('订单号后四位不正确');
            }
        };
        const CraftList = ({ crafts }) => {
            const handleClick = (index) => {
                this.setState({ selectedIndex: index }, () => {
                    if (this.scrollToElement) {
                        this.scrollToElement.scrollIntoView({ behavior: 'smooth' });
                    }
                });
            };
            return (
                <div style={{ 
                    position: 'absolute', 
                    width: '100px', 
                    top: '35vh', 
                    left: '0px', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    zIndex: 999, 
                    height: 'auto', 
                }}>
                    {crafts.map((craft, index) => (
                        <div 
                            key={craft.id} 
                            className="craft-item" // 添加 CSS 类
                            onClick={() => handleClick(index)} // 添加点击事件处理函数
                        >
                            <strong style={{ 
                                marginLeft: '10px', 
                                whiteSpace: 'nowrap', // 防止文本换行
                                overflow: 'hidden', // 隐藏溢出的文本
                                textOverflow: 'ellipsis', // 使用省略号表示被隐藏的文本
                                flex: 1 // 确保文本占据剩余空间
                            }}>
                                {index + 1}. {craft.craft_name}
                            </strong>
                        </div>
                    ))}
                </div>
            );
        };
        const handleCancel = () => {
            this.setState({
                isModalVisible: false,
                isOrderInfoVisible: true, // 恢复订单信息下拉框
                isNext_step: false, // 确保“开始”按钮显示
                isLast_step: false, // 确保“开始”按钮显示
                orderInput: '' // 清空输入框
            });
        };
        return (
            <div style={{ height: '100vh', width: "100vw", display: 'flex', flexDirection: 'column' }}>
                <div className="cutpiece_menu">
                    <Link to="/order/list" className="return-link">
                        返回待缝纫列表
                    </Link>
                    <div className="userStyle">
                        {currentUser ? (
                            <>
                                <SmileFilled style={{ fontSize: 26, marginRight: 10 }} />
                                <div className='user_log'>{userName}</div>
                                <button className='log_out' onClick={this.handleLogout}>退出</button>
                            </>
                        ) : (
                            <p>未登录用户，请先登录。</p>
                        )}
                    </div>
                </div>
                <div className="content-sections">
                    <div className="content-section-1"style={{ position: 'relative', height: '100%' }}>
                        <div style={{ position: 'absolute', bottom: '20px', right: '0px', display: 'flex', flexDirection: 'column' }}>
                            <button className="stepTypeButton"
                                onClick={() => this.setState({ stepType: 'image' })}
                                style={{ 
                                    backgroundColor: stepType === 'image' ? '#007bff' : '#f8f9fa', 
                                    color: stepType === 'image' ? '#fff' : '#333',
                                    display: 'flex',
                                    flexDirection: 'column', 
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100px', // 设置一个合适的高度
                                    width: '50px' // 设置一个合适的宽度
                                }}
                            >
                                图文
                            </button>
                            <button className="stepTypeButton"
                                onClick={() => this.setState({ stepType: 'text' })}
                                style={{ 
                                    backgroundColor: stepType === 'text' ? '#007bff' : '#f8f9fa', 
                                    color: stepType === 'text' ? '#fff' : '#333',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100px', // 设置一个合适的高度
                                    width: '50px' // 设置一个合适的宽度 
                                }}
                            >
                                文字
                            </button>
                        </div>
                        <CraftList crafts={Sewing_mode} />
                        {isOrderInfoVisible && (
                            <div className="content-section-title">
                                <Collapse defaultActiveKey={['1']} className="custom-collapse">
                                    <Panel header={<span style={{ fontWeight: 'bold', fontSize: '16px' }}>展开订单信息</span>} key="1">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={this.state.detailData.fashion.cover} style={{ width: '70px', height: '70px', marginRight: '16px' }} alt="Fashion Cover" />
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#333', marginBottom: '4px' }}>
                                                    订单编号: {this.state.orderDetail.id}
                                                </span>
                                                <span style={{ fontSize: '12px', fontWeight: 'bold', color: 'gray' }}>
                                                    款式名：{this.state.orderDetail.fashion_name}
                                                </span>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>
                        )}
                        <div className="content-section-title">
                            <div className="text-container">
                                {outPanelFabric && (
                                    <span>面板：{outPanelFabric}</span>
                                )}
                                {innerPanelFabric && (
                                    <span>里板：{innerPanelFabric}</span>
                                )}
                            </div>
                            {/* 进度条 */}
                            <div className='custom-progress '>
                                <span className="content-section-1-1">进度</span><Progress percent={progressPercent} status="active" />
                            </div>
                        </div>
                        <div className='process_classify'>
                            <button
                                className={selectedButton === '裁剪' ? 'selected' : ''}
                                onClick={() => this.handleButtonClick('裁剪')}
                            >
                                裁剪
                            </button>
                            <button
                                className={selectedButton === '加衬' ? 'selected' : ''}
                                onClick={() => this.handleButtonClick('加衬')}
                            >
                                加衬
                            </button>
                            <button
                                className={selectedButton === '车缝' ? 'selected' : ''}
                                onClick={() => this.handleButtonClick('车缝')}
                            >
                                车缝
                            </button>
                            <button
                                className={selectedButton === '整烫' ? 'selected' : ''}
                                onClick={() => this.handleButtonClick('整烫')}
                            >
                                整烫
                            </button>
                            <button
                                className={selectedButton === '钉扣' ? 'selected' : ''}
                                onClick={() => this.handleButtonClick('钉扣')}
                            >
                                钉扣
                            </button>
                            <button
                                className={selectedButton === '包装' ? 'selected' : ''}
                                onClick={() => this.handleButtonClick('包装')}
                            >
                                包装
                            </button>
                            <button
                                className={selectedButton === '其他' ? 'selected' : ''}
                                onClick={() => this.handleButtonClick('其他')}
                            >
                                其他
                            </button>
                        </div>
                        <div className="content-section-1-2">

                            {
                                (Sewing_mode.length > 0) &&
                                (<div className="scrollable-edge-line-blocks">
                                    {renderEdgeLineBlocks(Sewing_mode)}
                                </div>)
                            }
                            {!isNext_step && (
                                <button className="BeginButtion"
                                    
                                    onClick={() => {
                                        // 提取订单号的前面部分和后面四位
                                        const orderNumber = this.state.orderDetail.id;
                                        const orderPrefix = orderNumber.slice(0, -4);
                                        const orderSuffix = '_ _ _ _ '; // 四个方框表示输入框
                                        this.setState({
                                            isModalVisible: true, // 显示弹窗
                                            isOrderInfoVisible: false, // 隐藏订单信息弹窗
                                            orderPrefix,
                                            orderSuffix,
                                        });
                                        

                                    }}>
                                    开始</button>
                            )}
                            {isLast_step && (
                                <div className='step_style'>
                                    <button className='lastButtion'
                                        onClick={() => {
                                            let { index, backgroundColorIndices } = this.state;
                                            if (index === 0) {
                                                console.error("已经是第一个了")
                                                return;
                                            }
                                            index = index - 1;
                                            const newVar = NewMap.get(`${index}`);
                                            const ayy = JSON.parse(`${newVar.lines}`);
                                            this.Render_line(ayy);
                                            backgroundColorIndices.pop(index);
                                            this.setState({
                                                index: index,
                                                backgroundColorIndices: backgroundColorIndices,
                                            })
                                            this.progressPercent();

                                        }}>上一步
                                    </button>
                                    <button className='nextButton'
                                        onClick={() => {
                                            var { index, Sewing_mode, backgroundColorIndices } = this.state;
                                            index = index + 1;
                                            if (index === Sewing_mode.length) {
                                                console.error("已经是最后一个")
                                                return;
                                            }
                                            const newVar = NewMap.get(`${index}`);
                                            const ayy = JSON.parse(`${newVar.lines}`);
                                            this.Render_line(ayy);
                                            backgroundColorIndices.push(index);
                                            this.setState({
                                                index: index,
                                                backgroundColorIndices: backgroundColorIndices,
                                            })
                                            const hasRiskWarning = newVar.selected_crafts && newVar.selected_crafts.risk_warning;
                                            if (hasRiskWarning) {
                                                notification.warning({
                                                    message: '风险警告',
                                                    description: newVar.selected_crafts.risk_warning,
                                                    duration: 4.5, // 提示窗口显示时间，单位秒
                                                    style: {
                                                        top: '50%',
                                                        left: '55%',
                                                        transform: 'translate(-50%, -50%)',
                                                        position: 'fixed',
                                                        color: 'red',
                                                    },
                                                })
                                            }else{
                                                notification.destroy()
                                            }    
                                            this.progressPercent();
                                        }}>下一步
                                    </button>
                                </div>
                            )}
                            <Modal
                                title="校验订单号"
                                visible={isModalVisible}
                                onOk={handleOk}
                                okText="确定"
                                onCancel={handleCancel}
                                cancelText="取消"
                            >
                                <span style={{ fontSize: '18px' }}>
                                    请输入订单号的
                                    <span style={{ color: 'red' }}>后四位</span>
                                    ：
                                </span>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '8px',fontSize: '18px' }}>{this.state.orderPrefix}</span>
                                <input
                                    type="text"
                                    value={orderInput}
                                    onChange={(e) => this.setState({ orderInput: e.target.value })}
                                    style={{ width: '70px', padding: '8px', boxSizing: 'border-box' ,height:'30px'}}
                                    maxLength={4}
                                    placeholder={this.state.orderSuffix}
                                />
                            </div>
                            </Modal>
                        </div>
                    </div>
                    <div className="content-section-2">
                        <span className="content-section-2-3" style={{ float: 'right', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',zIndex:'999' }}>
                            <input type="range" value={apply_scalar_value} onChange={(e) => {
                                apply_scalar(parseInt(e.target.value))
                                this.setState({ apply_scalar_value: parseInt(e.target.value) })
                        }} /></span>                    
                        <canvas 
                            onDoubleClick={this.handleCnvasSize}
                            id="canvas3d" 
                            className="content-section-2-1"
                            width={225} height={225}
                            ></canvas>
                        <canvas
                            id="con"
                            width={this.state.window_w}
                            height={this.state.window_h}
                            className="content-section-2-2"
                        ></canvas>
                    </div>
                </div>
            </div>
        );
    }
}

export default Work;